import React from 'react';
import { convertUSDToXOF, convertCADToXOF} from '../services/api';

export const DeviseConvContext = React.createContext();

const DeviseConvertContext = ({ children }) => {

    const [dollar_to_cfa_value, setDollar_to_cfa_value] = React.useState(-1);

    React.useEffect(() => {
        tauxChange();
    }, [])


    const tauxChange = async () => {

        try {
            const tx = await convertCADToXOF(1);
            setDollar_to_cfa_value(tx);
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <DeviseConvContext.Provider value={dollar_to_cfa_value}>
            {children}
        </DeviseConvContext.Provider>
    );
};


export default DeviseConvertContext;
