import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useAuth } from '../Contexts/AuthContext';



const Cover = () => {

    const { user } = useAuth();
    const navigate = useNavigate();

    const scrollToElement = () => {
        const element = document.getElementById('start');
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <div className=" h-auto" style={{ backgroundColor: "#423f3a" }}>
            <div className="container">
                <div className="row justify-content-center align-items-center pt-6 pt-lg-8 pb-md-6 pb-5">
                    <div className="col-md-12 text-center">

                        <h1 className="text-white font-weight-light mb-4">
                            <span className="typed-text font-weight-bold">Préparation au TCF Canada</span>
                        </h1>

                        <p className="lead text-white opacity-75 fs--1">Plateforme spécialisée dans la préparation du TCF Canada. Des
                            tests à conditions réelles.</p>
                        <button className="btn btn-outline-info my-4 fs--1 border-2x rounded-pill" style={{ fontWeight: "bolder" }} onClick={scrollToElement}>
                            S'entraîner
                        </button>

                        <button className="btn btn-outline-success my-4 fs--1 border-2x rounded-pill ml-1" style={{ fontWeight: "bolder" }} onClick={e => navigate('/calculatrice-nclc')}>
                            Calcul NCLC
                        </button>

                        <div className='mt-1'></div>
                        <TypeAnimation
                            sequence={[
                                'Sujets de Compréhension Orale',
                                1500, // wait 1s before replacing "Mice" with "Hamsters"
                                'Sujets de Compréhension écrite',
                                1500,
                                'Sujets d\'Expression Orale',
                                1500,
                                'Sujets d\'Expression Ecrite',
                                1500
                            ]}
                            wrapper="h6"
                            speed={30}
                            className='text-warning font-weight'
                            style={{ fontSize: '1rem', display: 'inline-block', textDecoration: 'underline' }}
                            repeat={Infinity}
                        />

                        <div className='mt-6'></div>

                        {
                            !user ? (
                                <Button onClick={e => navigate('/inscription')} variant="danger">M'inscrire gratuitement</Button>
                            ) : null
                        }

                        <Button onClick={() => window.location.href = 'https://bit.ly/examstcfcanada'} variant="primary" style={{marginLeft: 5}}>
                            Installer l'application android
                        </Button>

                    </div>
                </div>

            </div>
        </div>

    );
};

export default Cover;