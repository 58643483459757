import React from 'react';
import SignUp from '../components/SignUp';
import Menu from '../components/Navbar';
import Footer from '../components/Footer';


const P_Registration = () => {


    return (
        <div className='page pt-5 pb-6 h-100'>
            <Menu />
            <div style={{ marginTop: '12.5rem' }}></div>

            <SignUp />

            <div style={{ paddingBottom: '5rem' }}></div>
            <Footer />
        </ div>
    );
};

export default P_Registration;