import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Pour récupérer les paramètres et gérer la redirection
import { FaWhatsapp } from 'react-icons/fa';
import QRCode from "react-qr-code";// Pour générer le QR Code
import './PaymentSuccess.css';
import { getSubscription, getUser, getAppConfig, sendMailWithNodemailer } from "../../services/api";
import MySpinner from '../../components/MySpinner';


const P_SuccessPayment = () => {

    const { subscriptionID } = useParams();

    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [inProgress, setinProgress] = React.useState(true);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [appConfig, setAppConfig] = useState(null);


    const fetchSubscriptionDetails = async () => {

        try {

            const rsp = await getAppConfig();

            if (rsp.data.data) {
                setAppConfig(rsp.data.data);
            }

            const subscriptionsDatas = await getSubscription(subscriptionID);

            if (subscriptionsDatas.data.data) {
                setSubscriptionDetails(subscriptionsDatas.data.data);

                const userData = await getUser(subscriptionsDatas.data.data.userID);

                if (userData.data.data) {
                    setUser(userData.data.data);
                    setinProgress(false);
                    sendMailWithNodemailer({
                        from: 'services@exams-tcfcanada.com',
                        to_email: `examstcfcanada@gmail.com`,
                        from_name: 'EXAMS TCF CANADA',
                        subject: "ABONNEMENT PAR CARTE",
                        text: `Un utilisateur vient de s'abonner par Carte à l'offre en ${subscriptionsDatas.data.data?.offer?.name} de $${subscriptionsDatas.data.data?.offer?.price}. Les détails sont les suivants:\n\nEmail: ${userData.data.data?.email}\nPays: ${userData.data.data?.country}`
                    })
                }
                else {
                    navigate('/not-found');
                    setinProgress(false);
                    return;
                }
            }
            else {
                navigate('/not-found');
                setinProgress(false);
                return;
            }

        } catch (error) {
            navigate('/not-found');
            setinProgress(false);
            return;
        }
    };

    useEffect(() => {
        if (subscriptionID) {
            fetchSubscriptionDetails();
        } else {
            navigate('/not-found');
            setinProgress(false);
        }
    }, [subscriptionID]);


    const handlePrint = () => {
        window.print();
    };


    const formatDate = date => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString('fr-FR', options);
    };


    return (
        <div className="payment-success-container">
            <div className="payment-card">
                <div className="payment-header">
                    <img
                        src="../assets/img/adaptive-icon.png"
                        alt="Traitement du Paiement"
                        className="payment-image"
                    />
                    <h1 className="payment-title">Opération terminée avec succès !</h1>
                </div>
                <p className="payment-description">
                    Nous avons bien reçu votre demande. Nous sommes en train de traiter et vérifier votre paiement. Votre abonnement sera activé dans un délai d'une heure.
                </p>
                <p className="payment-note">
                    Si après ce délai, vous n'avez toujours pas accès à votre abonnement, contactez-nous via votre application mobile EXAMS TCF CANADA ou via ce numéro WhatsApp :
                </p>
                <a href={`https://wa.me/${appConfig?.customer_service_number || '+237652943007'}`} className="whatsapp-button">
                    <FaWhatsapp className="whatsapp-icon" /> {appConfig?.customer_service_number || '+237652943007'}
                </a>

                {/* Tableau des détails de la souscription */}
                <div className="subscription-details">
                    <h2 className="details-title">Détails de la souscription</h2>
                    <table className="details-table">
                        <thead>
                            <tr>
                                <th>Client</th>
                                <th>Email</th>
                                <th>Offre</th>
                                <th>Montant</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{user?.name}</td>
                                <td>{user?.email}</td>
                                <td>{subscriptionDetails?.offer?.name}</td>
                                <td>{`$${subscriptionDetails?.offer?.price}`}</td>
                                <td>{formatDate(subscriptionDetails?.createdAt)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* QR Code */}
                <div className="qr-code-section">
                    <QRCode value={`${subscriptionID} - ${user?.email}`} size={110} />
                </div>

                {/* Bouton d'impression */}
                <button onClick={handlePrint} className="print-button">
                    Imprimer la page
                </button>
            </div>

            <MySpinner loading={inProgress} />

        </div>
    );
};

export default P_SuccessPayment;
