import React from 'react';
import { getAppConfig } from "../services/api";


export const AppConfigurationContext = React.createContext();


const getConfiguration = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const rsp = await getAppConfig();
            const conf = rsp?.data?.data;
           
            resolve(conf);

        } catch (error) {
            resolve(null);
        }
    })

};


const AppConfigContext = ({ children }) => {

    const [appConfig, setAppConfig] = React.useState(null);

    React.useEffect(() => {

        const getAppConfiguration = async () => {
            const conf = await getConfiguration();
            setAppConfig(conf);
        };

        getAppConfiguration();

    }, []);


    return (
        <AppConfigurationContext.Provider value={appConfig}>
            {children}
        </AppConfigurationContext.Provider>
    );
};


export default AppConfigContext;
