import axios from "axios";
import { PaymentOperation, RandomGenerator } from '@hachther/mesomb-browser';

const baseUrl = "https://3zi2ld48j7.execute-api.us-east-2.amazonaws.com/v1";
const subscriptionBaseUrl = "https://wdqn0zo1ti.execute-api.us-east-2.amazonaws.com/v1"
const paymentBaseUrl = "https://tql4m5hep6.execute-api.us-east-2.amazonaws.com/v1"
const notificationBaseUrl = "https://tsscu51gn5.execute-api.us-east-2.amazonaws.com/v1";
const offerBaseUrl = "https://g5shelgxec.execute-api.us-east-2.amazonaws.com/v1";
const subjectBaseUrl = "https://nn2ti724ge.execute-api.us-east-2.amazonaws.com/v1";
const appConfigBaseUrl = "https://t9ssoennkh.execute-api.us-east-2.amazonaws.com/v1";
const couponBaseUrl = "https://d28khc94t9.execute-api.us-east-2.amazonaws.com/v1";


export function getAppConfig() {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: appConfigBaseUrl,
        url: `/getappconfig`
    }

    return axios(query);
}


export function getUser(userID) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: baseUrl,
        url: `/getuser`,
        params: { userID }
    }

    return axios(query);
}

export function findUserByEmail(email) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: baseUrl,
        url: `/finduserbyemail`,
        params: {
            email: email
        }
    }

    return axios(query);
}


export function findUserByPhone(phone) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: baseUrl,
        url: `/finduserbyphone`,
        params: {
            phone: phone
        }
    }

    return axios(query);
}


export function listConstantes() {
    return {
        'CO': {
            id: '64cf8dddd0ceaf1a68f7ec1c',
            name: 'Compréhension orale '
        },

        'CE': {
            id: '64cf8dded0ceaf1a68f7ec1e',
            name: 'Compréhension écrite '
        },

        'EO': {
            id: '64cf8dded0ceaf1a68f7ec20',
            name: 'Expréssion orale '
        },

        'EE': {
            id: '64cf8dded0ceaf1a68f7ec22',
            name: 'Expréssion écrite '
        }
    }
}


export function saveUser(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'POST',
        baseURL: baseUrl,
        url: `/saveuser`,
        data: JSON.stringify(data)
    }

    return axios(query);
}

export function listUsers(params) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: baseUrl,
        url: `/users`,
        params: params
    }

    return axios(query);
}

export function listCoupons(params) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: couponBaseUrl,
        url: `/listcoupons`,
        params: params
    }

    return axios(query);
}

export function findCoupon(params) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: couponBaseUrl,
        url: `/findcoupon`,
        params: params
    }

    return axios(query);
}

export function getOffer(offerID) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: offerBaseUrl,
        url: `/getoffer`,
        params: { offerID }
    }

    return axios(query);
}


export function createOffer(data) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'POST',
        baseURL: offerBaseUrl,
        url: `/createoffer`,
        data: data
    }

    return axios(query);
}

export function deleteOffer(data) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'DELETE',
        baseURL: offerBaseUrl,
        url: `/deleteoffer`,
        data: data
    }

    return axios(query);
}


export function listSubscriptions(params) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: subscriptionBaseUrl,
        url: `/listsubscriptions`,
        params: params
    }

    return axios(query);
}


export function getSubscription(subscriptionID) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: subscriptionBaseUrl,
        url: `/getsubscription`,
        params: { subscriptionID }
    }

    return axios(query);
}


export function listSubscriptionsHistory(params) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: subscriptionBaseUrl,
        url: `/listsubscriptionshistory`,
        params: params
    }

    return axios(query);
}

export function listOffers(params) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: offerBaseUrl,
        url: `/listoffers`,
        params: params
    }

    return axios(query);
}

export function initiateMesombPayment(paymentID, amount, service, payer, country) {

    const payment = new PaymentOperation({
        applicationKey: '01833cc8722c02098b9261909334442d68dfc899',
        accessKey: 'df738cbe-d7a2-42ce-be25-2ced5dd31168',
        secretKey: 'c54436f7-5e84-4195-9ff3-a8fc57a46546'
    });

    const response = payment.makeCollect({ amount, service, payer, nonce: RandomGenerator.nonce(), trxID: paymentID });

    return response;
}


export function savePayment(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'POST',
        baseURL: paymentBaseUrl,
        url: `/savepayment`,
        data: JSON.stringify(data)
    }

    return axios(query);
}



export function generateUniqueMongoDbID() {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: paymentBaseUrl,
        url: `/generatemongodbid`
    }

    return axios(query);
}

export function sendMailWithMailJet(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'POST',
        baseURL: notificationBaseUrl,
        url: `/sendmailwithmailjet`,
        data: JSON.stringify(data)
    }

    return axios(query);
}


export function saveSubscription(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'POST',
        baseURL: subscriptionBaseUrl,
        url: `/savesubscription`,
        data: JSON.stringify(data)
    }

    return axios(query);
}


export function addUserToken(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'POST',
        baseURL: notificationBaseUrl,
        url: `/addusertoken`,
        data: JSON.stringify(data)
    }

    return axios(query);
}


export function sendMailWithNodemailer(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'POST',
        baseURL: notificationBaseUrl,
        url: `/sendMailWithNodemailer`,
        data: JSON.stringify(data)
    }

    return axios(query);
}


export function sendPushNotification(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'POST',
        baseURL: notificationBaseUrl,
        url: `/sendPushNotification`,
        data: JSON.stringify(data)
    }

    return axios(query);
}


export function listTokens(params) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: notificationBaseUrl,
        url: `/listtokens`,
        data: params
    }

    return axios(query);
}

export function updateSubscription(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'PUT',
        baseURL: subscriptionBaseUrl,
        url: `/updatesubscription`,
        data: JSON.stringify(data)
    }

    return axios(query);
}


export function listsubjectsbyuser(params) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: subjectBaseUrl,
        url: `/listsubjectsbyuser`,
        params: params
    }

    return axios(query);
}



export function addExpressionSubject(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'POST',
        baseURL: subjectBaseUrl,
        url: `/addexpressionsubject`,
        data: JSON.stringify(data)
    }

    return axios(query);
}

export function editOrDeleteQuestion(data) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'PUT',
        baseURL: subjectBaseUrl,
        url: `/editordeletequestion`,
        data: JSON.stringify(data)
    }

    return axios(query);
}

export function listSessionsSubjects(params) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: subjectBaseUrl,
        url: `/listSubjectsSessions`,
        params: params
    }

    return axios(query);
}


export function verifyUserAccessToSubject(params) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: subjectBaseUrl,
        url: `/verifyuseraccesstosubject`,
        params: params
    }

    return axios(query);
}


export function getSubject(params) {

    const query = {

        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        baseURL: subjectBaseUrl,
        url: `/getsubject`,
        params: params
    }

    return axios(query);
}


export function convertDevice(params, next) {

    const query = {
        headers: {
            'Accept': 'application/json'
        },

        method: 'GET',
        url: `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${params.from}.json`
    }

    axios(query).then((res) => {
        next(Math.round(parseFloat(res.data[params.from][params.to]) * params.amount));
    }).catch(err => {
        next(null);
    })
}




export async function convertUSDToXOF(amount) {

    // Liste des URL des API
    const apiUrlList = [
        `https://open.er-api.com/v6/latest/USD`,
        `https://api.exchangerate-api.com/v4/latest/USD`,
        `https://api.frankfurter.app/latest?from=USD&to=XOF`
    ];

    // Fonction récursive pour essayer chaque API jusqu'à ce que la conversion réussisse
    async function tryConversion(apiUrls, index) {
        if (index >= apiUrls.length) {
            // Aucune API disponible ou toutes les API ont été épuisées
            throw new Error('Conversion de devise impossible. Veuillez réessayer plus tard.');
        }

        try {
            const response = await axios.get(apiUrls[index]);
            const data = response.data;

            if (data && data.rates && data.rates.XOF) {
                const rate = data.rates.XOF;
                const convertedAmount = amount * rate;
                return Math.floor(convertedAmount); // Arrondir à l'entier le plus proche en dessous
            } else {
                throw new Error('Taux de change introuvable dans la réponse.');
            }
        } catch (error) {
            console.log(`Erreur lors de la tentative de conversion avec l'API ${index + 1}: ${error.message}`);
            // Essayez la prochaine API
            return tryConversion(apiUrls, index + 1);
        }
    }

    try {
        const convertedAmount = await tryConversion(apiUrlList, 0);
        return convertedAmount;
    } catch (error) {
        throw error;
    }

}


export async function convertCADToXOF(amount) {

    // Liste des URL des API pour CAD
    const apiUrlList = [
        `https://open.er-api.com/v6/latest/CAD`,
        `https://api.exchangerate-api.com/v4/latest/CAD`,
        `https://api.frankfurter.app/latest?from=CAD&to=XOF`
    ];

    // Fonction récursive pour essayer chaque API jusqu'à ce que la conversion réussisse
    async function tryConversion(apiUrls, index) {
        if (index >= apiUrls.length) {
            // Aucune API disponible ou toutes les API ont été épuisées
            throw new Error('Conversion de devise impossible. Veuillez réessayer plus tard.');
        }

        try {
            const response = await axios.get(apiUrls[index]);
            const data = response.data;

            if (data && data.rates && data.rates.XOF) {
                const rate = data.rates.XOF;
                const convertedAmount = amount * rate;
                return Math.floor(convertedAmount); // Arrondir à l'entier le plus proche en dessous
            } else {
                throw new Error('Taux de change introuvable dans la réponse.');
            }
        } catch (error) {
            console.log(`Erreur lors de la tentative de conversion avec l'API ${index + 1}: ${error.message}`);
            // Essayez la prochaine API
            return tryConversion(apiUrls, index + 1);
        }
    }

    try {
        const convertedAmount = await tryConversion(apiUrlList, 0);
        return convertedAmount;
    } catch (error) {
        throw error;
    }

}

