import React, { useEffect } from 'react';
import Menu from '../components/Navbar';
import Container from 'react-bootstrap/Container';
import Footer from '../components/Footer';
import Cover from '../components/Cover';
import SubjectPresentation from '../components/SubjectPresentation';
import CustomTitle from '../components/CustomTitle';
import Pricing from '../components/Pricing';
import CalculatriceNcLc from '../components/CalculatriceNcLc';
import ReactGA from 'react-ga';
import '../css/vitrineImmigration.css';
import { useNavigate } from 'react-router-dom';

const P_Home = () => {

    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.pageview('/accueil');

        // Commencez à mesurer la durée de session lorsque l'utilisateur accède à la page
        ReactGA.event({
            category: 'Session',
            action: 'Start',
            label: 'accueil'
        });

        return () => {
            // Terminez la mesure de la durée de session lorsque l'utilisateur quitte la page
            ReactGA.event({
                category: 'Session',
                action: 'End',
                label: 'accueil'
            });
        }
    }, []);

    return (
        <>
            <Menu />
            <div style={{ marginTop: '9.5rem' }}></div>
            <Cover />
            <Container className=''>
                <SubjectPresentation />
                <CustomTitle
                    part1={'NOS FORFAITS '}
                    part2={'POUR VOUS FORMER'}
                    part3={' CHEZ NOUS'}
                    description={"Faites vos choix, abonnez-vous, et commencez aujourd'hui"}
                />
                <div style={{ marginTop: '2rem' }}></div>
                <Pricing />
                <div style={{ marginTop: '5rem' }}></div>

                {/* <CustomTitle
                    part1={'CREER UN COMPTE '}
                    part2={'GRATUITEMENT'}
                    part3={' SUR NOTRE PLATEFORME'}
                    description={"Vous avez besoin d'un compte pour pouvoir vous abonner à une offre"}
                /> */}

                {/*  <SignUp /> */}

                {/* <div style={{ marginTop: '4rem' }}></div> */}


                <div className="service-section">
                    <div className="content-container">
                        <h2 className="section-title">Services de consultation en ligne pour l'immigration au Canada.</h2>
                        <p className="section-description">
                            Vous souhaitez immigrer au Canada avec votre famille, mais vous ne savez pas par où commencer? Nous pouvons vous guider à distance grâce à notre service Premium de Consultation en Immigration pour le Canada.
                            Pour obtenir davantage d'informations sur cette offre et y souscrire, veuillez cliquer sur le bouton ci-dessous.
                        </p>
                        <bouton className="en-savoir-plus-button" onClick={e => navigate('/accompagnement-immigration')} >En savoir plus</bouton>
                    </div>
                </div>


                <div style={{ marginTop: '5rem' }}></div>

                <CustomTitle
                    part1={'CALCULER VOTRE SCORE NCLC '}
                    part2={'GRATUITEMENT'}
                    part3={' SUR NOTRE PLATEFORME'}
                />

                <CalculatriceNcLc />

                <div style={{ marginTop: '4rem' }}></div>




                {/* <CustomTitle
                    part1={''}
                    part2={'RESULTATS DE NOS CANDIDATS '}
                    part3={''}
                    description={"Bonjour le monde"}
                /> */}

                {/* <CustomCarousel /> */}
                {/* <ImageCarousel /> */}
                {/*  <div style={{ marginTop: '4rem' }}></div> */}


            </Container>
            <div style={{ marginTop: '2rem' }}></div>
            <Footer />
        </>
    );
};

export default P_Home;