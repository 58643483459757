import React from 'react';
import Login from '../components/Login';
import Menu from '../components/Navbar';
import Footer from '../components/Footer';


const P_Login = () => {


    return (
        <div className='col-12 pt-5 pb-6 h-100'>
            <Menu />
            <div style={{ marginTop: '12.5rem' }}></div>
            <Login />
            <div style={{ paddingBottom: '5rem' }}></div>
            <Footer />
        </ div>
    );
};

export default P_Login;