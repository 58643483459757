import React from 'react';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { NavLink } from 'react-router-dom'

const SubjectPresentation = () => {

    return (
        <section className="bg-light text-center">
            <div className="container">

                <div className="row">
                    <div className="col">
                        <h1 id='start' className="font-weight-black fs-2 fs-sm-4 fs-md-5">PREPARATION AU TCF CANADA</h1>
                        <p className="lead">Nous vous proposons des sujets tirés de l'examen du TCF Canada</p>
                    </div>
                </div>

                <div className="row mt-6">

                    <div className="col-lg-4">
                        <NavLink to={'/sujets-comprehension-orale'}>
                            <div className="card card-span w-100">
                                <div className="card-span-img">
                                    <HeadphonesIcon className='text-info' style={{ fontSize: '3rem' }} />
                                </div>
                                <div className="card-body pt-6 pb-4">
                                    <h5 className="mb-2">Compréhension Orale</h5>
                                    <p>L'épreuve de compréhension orale au TCF Canada évalue la capacité des candidats à comprendre et à interpréter des enregistrements audio en français.
                                        <NavLink to={'/article-comprehension-orale'}> En savoir d'avantage sur cette épreuve</NavLink>
                                    </p>
                                    <NavLink to={'/sujets-comprehension-orale'} className="btn btn-outline-info border-2x rounded-pill btn-md mt-4 fs-0 py-1" type="button">M'entraîner</NavLink>
                                </div>
                            </div>
                        </NavLink>
                    </div>


                    <div className="col-lg-4 mt-6 mt-lg-0">
                        <NavLink to={'/sujets-comprehension-ecrite'}>

                            <div className="card card-span w-100">
                                <div className="card-span-img">
                                    <MenuBookOutlinedIcon className='text-danger' style={{ fontSize: '3rem' }} />
                                </div>
                                <div className="card-body pt-6 pb-4">
                                    <h5 className="mb-2">Compréhension écrite</h5>
                                    <p>L'épreuve de compréhension écrite au TCF Canada vise à évaluer la capacité des candidats à comprendre et à interpréter des textes écrits en français.
                                        <NavLink to={'/article-comprehension-ecrite'}> En savoir d'avantage sur cette épreuve</NavLink>
                                    </p>
                                    <NavLink to={'/sujets-comprehension-ecrite'} className="btn btn-outline-danger border-2x rounded-pill btn-md mt-4 fs-0 py-1" type="button">M'entraîner</NavLink>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-lg-4 mt-6 mt-lg-0">
                        <NavLink to={'/sujets-expression-orale'}>
                            <div className="card card-span w-100">
                                <div className="card-span-img">
                                    <PermCameraMicIcon className='text-success' style={{ fontSize: '3rem' }} />
                                </div>
                                <div className="card-body pt-6 pb-4">
                                    <h5 className="mb-2">Expression orale</h5>
                                    <p>Cette épreuve évalue la capacité des candidats à déterminer le niveau de maîtrise du français dans des situations de la vie quotidienne.
                                        <NavLink to={'/article-expression-orale'}> En savoir d'avantage sur cette épreuve</NavLink>
                                    </p>
                                    <NavLink to={'/sujets-expression-orale'} className="btn btn-outline-success border-2x rounded-pill btn-md mt-4 fs-0 py-1" type="button">Vor les sujets</NavLink>
                                </div>
                            </div>
                        </NavLink>
                    </div>


                </div>

                <div className='row mt-6 justify-content-center align-items-center'>
                    <div className="col-lg-4 mt-6 mt-lg-0 ">
                        <NavLink to={'/sujets-expression-ecrite'}>
                            <div className="card card-span w-100 ">
                                <div className="card-span-img mt-3">
                                    <KeyboardIcon className='text-warning' style={{ fontSize: '3rem' }} />
                                </div>
                                <div className="card-body pt-6 pb-4">
                                    <h5 className="mb-2">Expression écrite</h5>
                                    <p>L'épreuve d'Expression écrite vise à évaluer l'aptitude des candidats à exprimer des idées de manière claire et cohérente, à développer des arguments et à utiliser correctement la langue francçaise.
                                        <NavLink to={'/article-expression-ecrite'}> En savoir d'avantage sur cette épreuve</NavLink>
                                    </p>
                                    <NavLink to={'/sujets-expression-ecrite'} className="btn btn-outline-warning border-2x rounded-pill btn-md mt-4 fs-0 py-1" type="button">Vor les sujets</NavLink>
                                </div>
                            </div>
                        </NavLink>
                    </div>

                </div>

            </div>
        </section>

    );
};

export default SubjectPresentation;