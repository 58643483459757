import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import '../css/ImmigrationForm.css';
import MyToast from './MyToast';
import MySpinner from './MySpinner';
import InfoModal from './Modal/InfoModal';

const data = {
    fullName: '',
    gender: 'Homme',
    country: 'Cameroon',
    email: '',
    phone: ''
}

const ImmigrationForm1 = () => {

    const [formData, setFormData] = useState(data);


    const [countries, setCountries] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [img, setImg] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });



    React.useEffect(() => {
        // Fonction pour charger la liste des pays depuis l'API REST Countries
        const fetchCountries = async () => {
            try {
                const response = await fetch('https://restcountries.com/v3.1/all');
                const data = await response.json();

                // Triez les pays par nom
                data.sort((a, b) => a.name.common.localeCompare(b.name.common));

                setCountries(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données depuis l\'API :', error);
            }
        };

        // Appelez la fonction pour charger la liste des pays lors du montage du composant
        fetchCountries();
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChange1 = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.fullName) {
            setOpenToast({
                open: true,
                msg: 'Veuillez fournir votre nom',
                severity: 'error'
            })

            return;
        }

        if (!formData.phone && !formData.email) {
            setOpenToast({
                open: true,
                msg: 'Vous devez fournir au moins un numéro de téléphone WhatsApp ou un adresse email valide.',
                severity: 'error'
            })

            return;
        }

        setLoading(true)

        axios.post('https://tsscu51gn5.execute-api.us-east-2.amazonaws.com/v1/sendmailwithmailjet', JSON.stringify({
            from: 'services@exams-tcfcanada.com',
            to_email: 'examstcfcanada@gmail.com',
            to_name: 'Marius',
            from_name: 'TCF CANADA',
            subject: "Souscription à l'offre d'accompagnement sur mobile",
            text: "Un client vient de souscrire à l'offre de consultation pour l'immigration. Les details: \n\n" +
                JSON.stringify({
                    ...formData, ...{
                        subscriptionDate: new Date(),
                        subscriptionEndDate: new Date((new Date()).setMonth((new Date()).getMonth() + 2))
                    }
                })
        })).then(() => {

            setOpenToast({
                open: true,
                msg: 'Votre inscription a été réussie. Nous vous recontacterons dans les plus brefs délais.',
                severity: 'success'
            })

            setFormData(data);

            setLoading(false);

        }).catch(err => {

            setOpenToast({
                open: true,
                msg: 'Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard, s\'il vous plaît.',
                severity: 'error'
            })

        }).finally(() => {
            setLoading(false)
        })

    };


    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={8}>
                    <>
                        <h2 className="mt-6 mb-4">Services de consultation en ligne pour l'immigration au Canada.</h2>

                        <p>
                            Vous souhaitez immigrer au Canada avec votre famille ? Nous pouvons vous accompagner à distance grâce à notre service Premium de Consultation en Immigration pour le Canada.
                            <a href="#">
                                <span> </span> Veuillez remplir le formulaire ci-dessous pour vous enregistrer.
                            </a>
                        </p>

                        {/* <Modal show={showInfoModal} onHide={handleCloseInfo}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Offre Premium à la Consultation en Immigration pour le Canada</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Alert variant="info">
                                        Pour démarrer votre projet d'immigration au Canada, bénéficiez de notre service d'assistance personnalisée via
                                        {' '}
                                        <span style={{ color: '#25D366' }}>WhatsApp</span>,
                                        moyennant des frais de 10 000 XAF, à régler lors de la souscription à cette offre exclusive.
                                        <p></p>
                                        Plus spécifiquement, nous sommes en mesure de vous accompagner sur les points essentiels suivants :
                                        <p></p>
                                        <ul>
                                            <li>Présentation des différents programmes d'immigration</li>
                                            <li>Assistance pour choisir le meilleur programme selon votre profil</li>
                                            <li>Liste des étapes à suivre et des principaux documents nécessaires</li>
                                            <li>Estimation du montant total de votre procédure</li>
                                            <li>Fourniture de liens importants pour compléter vos informations</li>
                                            <li>Orientations vers des professionnels pouvant vous aider étape par étape à moindre coût</li>
                                        </ul>
                                    </Alert>

                                    <Alert variant="secondary">
                                        L'assistance en ligne est disponible chaque jour entre 09h du matin et 18h du soir.
                                        De plus, l'offre est valide pour une durée de 2 mois à compter de la date de souscription.
                                    </Alert>

                                    <Alert variant="danger">
                                        <strong>Importante :</strong> La souscription en ligne pour cette offre
                                        n'est accessible qu'aux Camerounais pour le moment. Les personnes des autres pays
                                        intéressées peuvent nous contacter via{' '}
                                        <a href="/contactez-nous" style={{ color: '#FF0000' }}>
                                            notre formulaire de contact
                                        </a>
                                        .
                                        <p>
                                            Veuillez noter que cette offre n'implique pas que nous devions réaliser votre procédure d'immigration nous-mêmes. Elle ne concerne que les points susmentionnés.
                                        </p>
                                    </Alert>



                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseInfo}>
                                        Fermer
                                    </Button>
                                </Modal.Footer>
                            </Modal> */}

                        <Form onSubmit={handleSubmit} className='mt-5'>
                            <h3>Formulaire d'enregistrement à l'offre</h3>
                            {/* Form fields */}
                            <Form.Group controlId="fullName" className='mb-3 mt-4'>
                                <Form.Label>Votre nom complet <span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    required={true}
                                />
                            </Form.Group>

                            <Form.Group controlId="gender" className='mb-3'>
                                <Form.Label>Sexe <span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    as="select"
                                    name="gender"
                                    required={true}
                                    value={formData.gender}
                                    onChange={handleChange}
                                >
                                    <option value="Masculin">Homme</option>
                                    <option value="Féminin">Femme</option>
                                </Form.Control>
                            </Form.Group>

                            {/* <Form.Group controlId="country" className='mb-3'>
                                    <Form.Label>Pays <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="country"
                                        required={true}
                                        value={formData.country}
                                        onChange={handleChange}
                                        disabled
                                    >
                                        <option value="Cameroon">Cameroun</option>
                                    </Form.Control>
                                </Form.Group> */}

                            <div className="form-field animation a3 mt-2">
                                <label>Pays</label>
                                <select className="custom-select" value={formData.country} name="country" id="country" onChange={handleChange1} style={{ height: '40px', marginBottom: 20 }} >
                                    {
                                        countries.map((country) => (
                                            <option key={country.cca2} value={country.name.common}>
                                                {country.name.common}
                                            </option>

                                        ))
                                    }
                                </select>
                            </div>


                            <Form.Group controlId="email" className='mb-3'>
                                <Form.Label>Adresse Email </Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="phone" className='mb-3'>
                                <Form.Label>Numéro WhatsApp </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="phone"
                                    placeholder='Exemple: +237652943007'
                                    required={false}
                                    style={{ opacity: 0.8 }}
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            {/*  <div className="mt-6">
                                    <h3>Paiement</h3>
                                    <p>
                                        Veuillez vous acquitter de la somme de <span style={{ color: '#007BFF', fontWeight: 'bold' }}>10 000 XAF</span> pour finaliser le processus d'enregistrement.
                                    </p>

                                    <Form.Group controlId="paymentMethod" className='mb-3 mt-4'>
                                        <Form.Label>Méthode de Paiement <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control as="select" name="paymentMethod"
                                            value={formData.paymentMethod}
                                            onChange={handleChange}
                                        >
                                            <option value="orange">Orange Money</option>
                                            <option value="mtn">MTN Money</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="paymentNumber" className='mb-3'>
                                        <Form.Label>Entrez votre numéro de paiement <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="paymentNumber"
                                            value={formData.paymentNumber}
                                            onChange={handleChange}
                                            required={true}
                                            placeholder='Exemple: 691402415'
                                            style={{ opacity: 0.8 }}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="termsCheckbox" className='mb-3'>
                                        <Form.Check
                                            type="checkbox"
                                            label="J'ai lu et j'accepte les termes de l'offre"
                                            checked={termsAccepted}
                                            required={true}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Form.Group>

                                    <Button variant="success" type='submit' >
                                        Payer et souscrire
                                    </Button>

                                </div>

                                
 */}

                            <Button variant="success" type='submit' >
                                Soumettre
                            </Button>
                        </Form>

                    </>
                </Col>

            </Row>

            <InfoModal
                visible={visible}
                title={title}
                msg={msg}
                setVisible={setVisible}
                img={img}
            />

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={loading} />
        </Container>
    );
};

export default ImmigrationForm1;
