import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
/* import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { addUserToken } from "./services/api";
import Cookies from 'js-cookie';

const Notification_key_pair = "BE-SzU-Ooc-Nj9MdePzvcmxb65r0iavHuKy8Amr0gVl3CBUHc3G4zuYkO3D1ZKvdu3wEXJ7vC1XXzRYnBgxbShg";
 */
const firebaseConfig = {
    apiKey: "AIzaSyA_9tCjjCCm_W6m46Sa_xAX69GD0yJz3zs",
    authDomain: "tcf-canada-5620d.firebaseapp.com",
    projectId: "tcf-canada-5620d",
    storageBucket: "tcf-canada-5620d.appspot.com",
    messagingSenderId: "631417348682",
    appId: "1:631417348682:web:96bf2f030f43e6bc953cb3",
    measurementId: "G-0CJKQ28N22"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
/* export const messaging = getMessaging(app); */

/* 
export const requestPermission = () => {

    console.log("Requesting User Permission......");
    Notification.requestPermission().then((permission) => {

        if (permission === "granted") {

            console.log("Notification User Permission Granted.");
            return getToken(messaging, { vapidKey: Notification_key_pair })
                .then((currentToken) => {

                    if (currentToken) {
                        console.log('Client Token: ', currentToken);
                        addUserToken({token: currentToken});
                        Cookies.set('notif_token', currentToken);
                    } else {
                        console.log('Failed to generate the app registration token.');
                    }
                })
                .catch((err) => {
                    console.log('An error occurred when requesting to receive the token.', err);
                });
        } else {
            console.log("User Permission Denied.");
        }
    });

}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

requestPermission(); */