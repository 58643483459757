import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer-48201 py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 pr-md-5">
                        <NavLink to={'/accueil'} className="footer-site-logo d-block mb-4">EXAMS-TCFCANADA</NavLink>
                        <p>Plateforme spécialisée dans la préparation au TCF Canada</p>
                    </div>
                    <div className="col-md">
                        <ul className="list-unstyled nav-links">
                            <li><NavLink to={'/accueil'}>Acceuil</NavLink></li>
                            <li><NavLink to={'/a-propos'}>A propos de nous</NavLink></li>
                            <li><NavLink to={'/contactez-nous'}>Contacts</NavLink></li>
                            <li><NavLink to={'/politique-confidentialite'}>Politique de confidentialité</NavLink></li>
                        </ul>
                    </div>
                    <div className="col-md">
                        <ul className="list-unstyled nav-links">
                            <li><NavLink to={'/sujets-comprehension-orale'}>Compréhension Orale</NavLink></li>
                            <li><NavLink to={'/sujets-comprehension-ecrite'}>Compréhension Ecrite</NavLink></li>
                            <li><NavLink to={'/sujets-expression-orale'}>Expression Orale</NavLink></li>
                            <li><NavLink to={'/sujets-expression-ecrite'}>Expression Ecrite</NavLink></li>
                            <li><NavLink to={'/calculatrice-nclc'} className="text-success" style={{fontWeight: 'bolder'}}>Calculatrice NCLC</NavLink></li>
                            <li><NavLink to={'/offres'} className="text-success" style={{fontWeight: 'bolder'}}>Nos offres</NavLink></li>
                        </ul>
                    </div>
                    <div className="col-md text-md-center">
                        <ul className="social list-unstyled">
                            <li><a href="https://wa.me/18192530943"><span className="icon-whatsapp" /></a></li>
                            <li><NavLink href="#"><span className="icon-instagram" /></NavLink></li>
                            <li><a href="https://www.facebook.com/profile.php?id=61562693859324"><span className="icon-facebook" /></a></li>
                        </ul>
                        <p>
                            <NavLink to={'/contactez-nous'} className="btn btn-tertiary">Contactez-nous</NavLink>
                        </p>
                        <p>
                            <a href="https://bit.ly/examstcfcanada" className="btn btn-tertiary d-flex align-items-center justify-content-center" style={{backgroundColor: '#000000', color: '#fff'}}>
                                <img src='../assets/img/playstore.webp' alt="Play Store Logo" style={{width: '50px', marginRight: '5px'}} />
                                Télécharger l'application Android
                            </a>
                        </p>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12 text-center">
                        <div className="copyright mt-5 pt-5">
                            <p><small>exams-tcfcanada© 2023 Tous droits réservés.</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
