import React from 'react';
import Menu from '../components/Navbar';
import LoadingOverlay from 'react-loading-overlay';
import Container from 'react-bootstrap/Container';
import MyToast from '../components/MyToast';
import Footer from '../components/Footer';
import { getSubject } from '../services/api';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { auth } from "../firebase";
import SubjectTitleSecond from '../components/SubjectTitleSecond';
import { onAuthStateChanged } from "firebase/auth";
import TextWithLineBreaks from '../components/Utils/TextWithLineBreaks';
import EditAndDeleteQuestion from '../components/Admin/EditAndDeleteQuestion';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


const P_Subject_E = () => {

    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const subjectID = searchParams.get('subject_id') || null;
    const subjectType = searchParams.get('subject_type') || null;
    const [questions, setQuestions] = React.useState([]);
    const [current_questions, setCurrent_questions] = React.useState([]);
    const [currentTache, setCurrentTache] = React.useState(subjectType ? (subjectType == 'eo' ? 2 : 1) : 2);
    const [subject, setSubject] = React.useState(null);

    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });


    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                setUser(authUser)
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);


    React.useEffect(() => {

        if (!subjectID || !subjectType) {
            navigate('/error');
            return;
        }

        setLoading(true);

        getSubject({ subjectID }).then(response => {
            const tmp = response.data.data;

            if (!tmp) {
                setLoading(false);
                navigate('/not-found');
                return;
            }

            setLoading(false);
            setSubject(tmp);
            setQuestions(tmp?.questions);

        }).catch(err => {
            setLoading(false);
            navigate('/error');
            return;
        })

    }, [subjectID, navigate])


    React.useEffect(() => {

        const tmp = []
        for (const quest of questions) {
            if (quest.questionNumber && quest.questionNumber === currentTache) {
                tmp.push(quest);
            }
        }

        setCurrent_questions(tmp);

    }, [questions, currentTache])


    const handleChange = e => {
        setCurrentTache(parseInt(e.target.value));
    } 

    console.log(current_questions);
    

    return (
        <>
            <Menu />
            <LoadingOverlay
                active={loading}
                spinner
                className='fs-md-0 fs--1'
                text=''>

                <div style={{ marginTop: '9.5rem' }}></div>

                <SubjectTitleSecond
                    img={"../assets/img/cover-question1.jpg"}
                    title={`EXPRESSION ${subjectType === 'eo' ? 'ORALE' : 'ÉCRITE'} SUJETS DE ${subject?.session}`}
                    description={'Attention ! Ces sujets sont inspirés de vrais essais ! Ils ne constituent pas les originaux.'}
                />

                <div style={{ marginTop: '2rem' }}></div>

                <Container className='mt-6'>
                    <div className='d-flex justify-content-center'>
                        <h6>Choisir le type de tâche</h6>
                    </div>

                    <div className='d-flex justify-content-center mb-4'>

                        <div>
                            <select onChange={handleChange} value={currentTache} className="custom-select custom-select-lg mb-3 text-center text-success text-uppercase" style={{ fontSize: 'large', fontWeight: 'bold', cursor: 'pointer' }}>
                                <option className='text-uppercase' value={'1'} >Tâche 1</option>
                                <option className='text-uppercase' value={'2'}>Tâche 2</option>
                                <option className='text-uppercase' value={'3'}>Tâche 3</option>
                            </select>
                        </div>
                    </div>


                    {
                        current_questions.map((question, index) => (
                            <div className="card mb-5">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">
                                                <a href="#">Sujet {index + 1}</a>
                                                {
                                                    (user && (user?.email === 'mfi.tene@gmail.com' || user?.email === 'tefuncowm@gmail.com')) ? (
                                                        <EditAndDeleteQuestion
                                                            key={index + 1}
                                                            consigne={question?.consigne}
                                                            free={question?.free === undefined ? true : question?.free}
                                                            questionID={question?._id}
                                                            reponse={question?.response}
                                                            subjectID={subjectID}
                                                            setOpenToast={setOpenToast}
                                                        />
                                                    ) : null
                                                }
                                            </h5>

                                        </div>

                                    </div>
                                </div>

                                <div className="card-body bg-light border-top">
                                    <div className="row">
                                        <div className='col-12'>
                                            <p className='sujet_oral'>
                                                <TextWithLineBreaks text={question?.consigne} />
                                            </p>

                                            {
                                                (question.image_base_url && question.image_base_url !== "") ? (
                                                    <div className='card'>
                                                        <a href={question.image_base_url}>
                                                            <img style={{ maxHeight: '300px' }} className="card-img-top" src={question.image_base_url} alt />
                                                        </a>
                                                    </div>
                                                ) : null
                                            }


                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top">
                                    {
                                        (question.response && question.response !== "") ? (
                                            <>

                                                {
                                                    (question.free != undefined && (question.free === false || question.free === 'false')) ? (
                                                        <button className="text-center" data-toggle="collapse" style={{ color: 'olive' }} role="button" aria-expanded="false" >
                                                            Abonnez-vous pour voir cette Proposition de solution <LockOutlinedIcon />
                                                        </button>
                                                    ) : (
                                                        <button className="text-center" data-toggle="collapse" style={{ color: 'olive' }} href={`#correct_tache${currentTache}_sujet${index}`} role="button" aria-expanded="false" aria-controls={`#correct_tache${currentTache}_sujet${index}`}>
                                                            Proposition de solution <ExpandMoreIcon />
                                                        </button>
                                                    )
                                                }

                                                <div className="collapse mt-3" id={`correct_tache${currentTache}_sujet${index}`}>
                                                    <div className="border p-card rounded sujet_oral">
                                                        <TextWithLineBreaks text={question?.response} />
                                                    </div>
                                                </div>
                                            </>
                                        ) : null
                                    }
                                </div>
                            </div>
                        ))
                    }

                </Container>

                <div style={{ marginTop: '2rem' }}></div>
                <Footer />

                <MyToast
                    openToast={openToast}
                    setOpenToast={setOpenToast}
                />

            </LoadingOverlay>


        </>
    );
};

export default P_Subject_E;