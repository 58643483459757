import React from 'react';
import Menu from '../components/Navbar';
import Container from 'react-bootstrap/Container';
import Footer from '../components/Footer';
import Cover from '../components/Cover';
import ImmigrationForm from '../components/ImmigrationForm';
import ImmigrationForm1 from '../components/ImmigrationForm1';


const P_AccompagnementImmigration = () => {
    return (
        <>
        <Menu />
        <div style={{ marginTop: '11rem' }}></div>
        <Container className=''>
            <ImmigrationForm1 />
        </Container>
        <div style={{ marginTop: '2rem' }}></div>
        <Footer />
    </>
    );
};

export default P_AccompagnementImmigration;