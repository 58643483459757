import React from 'react';
import SubjectBtn from './SubjectBtn';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth } from '../Contexts/AuthContext';
import { listsubjectsbyuser, listSessionsSubjects} from '../services/api';

const SubjectsList = ({ subjectTypeID, subjectName, redirect, subjectCode}) => {

    const { user } = useAuth();
    const [sujets_, setSujets_] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39]);
    const [sujets, setSujets] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    React.useEffect(() => {
        setLoading(true);
        setSujets([]);

        if(subjectCode === 'CO' || subjectCode === 'CE') {
            loadOralesSubjects();
        }
        else if(subjectCode === 'EO' || subjectCode === 'EE') {
            loadExpressionsSubjects();
        }

    }, [user])


    const loadOralesSubjects = () => {

        listsubjectsbyuser({ subjectTypeID: subjectTypeID, userID: user?.uid })
            .then(resp => {
                const data = resp.data;
                const all_subjects = data.data?.all_subjects || [];
                const _subjects = data.data?.subjects || [];
                const tmp = [];

                for (const _suj of all_subjects) {
                    const available = checkExistance(_subjects, _suj);
                    const tmp_ = { ..._suj, ...{ available } };
                    tmp.push(tmp_);
                }

                setSujets(tmp);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }


    const loadExpressionsSubjects = () => {
        listSessionsSubjects({ subjectTypeID: subjectTypeID })
        .then(resp => {
            const data = resp.data;
            const all_subjects = data.data?.sessions || [];

            const tmp = []

            for(const suj of all_subjects) {
                if(suj.session) {
                    tmp.push(suj);
                }
            }

            setSujets(tmp);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        })
    }

    const checkExistance = (all_subjects, subject) => {
        let exists = false;

        for (const suj of all_subjects) {
            if (suj._id === subject._id) {
                exists = true;
            }
        }

        return exists;
    }

    
    return (
        <div className='col-12'>
            <div className='row justify-content-center align-items-center text-center'>
                <div className='col-12'>
                    <Spinner animation="border" role="status" className={`${loading === true ? 'mb-2' : 'd-none'}`}></Spinner>
                </div>
                {
                    sujets.map((sujet, index) => (
                        <div className='d-flex justify-content-center col-12 col-sm-12 col-md-6 col-xl-4 mb-3'>
                            {
                                (subjectCode === 'CO' || subjectCode === 'CE') ? (
                                    <SubjectBtn
                                        lock={!sujet.available}
                                        label={ <><>{`${subjectName} `}</> <br /> <> {` sujet ${index + 1} ${sujet.serieNumber ? '(Série ' + sujet.serieNumber + ')': ''}`}</></>}
                                        path={`${redirect}?subject_id=${sujet._id}&subject_type=${subjectCode?.toLowerCase()}`}
                                    />
                                ) : (

                                    <SubjectBtn
                                        lock={false}
                                        label={sujet?.session}
                                        withArrow={true}
                                        path={`${redirect}?subject_id=${sujet._id}&subject_type=${subjectCode?.toLowerCase()}`}
                                    />
                                )
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default SubjectsList;

